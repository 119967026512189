<template>
  <div class="totalOrder-container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
      <!-- <el-tab-pane label="所有客户订单" name="renewalOrder"> -->
          <renewal-order ref="orderAll"></renewal-order>
      <!-- </el-tab-pane> -->
      <!-- <el-tab-pane label="我的客户订单" name="newOrder">
          <renewal-order ref="orderMy" :createFlag="1"></renewal-order>
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>
<script>
import RenewalOrder from "./renewalOrder.vue";
export default {
  components: {
    RenewalOrder,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.totalOrder-container {
}
</style>
